import React from "react";

const Ticket = React.forwardRef((props, ref) => {
  const {
    selectedPackage,
    nationality,
    visitingDate,
    selectedTimeSlot,
    name,
    gender,
    age,
    email,
    mobile,
    message,
    payment_status,
    qr,
    booking_id,
    order_id,
    utr_id,
    total_amount,
    activities,
  } = props;
  console.log(activities, "activities");

  // Parsing activities
  const adultActivities = activities?.adults || [];
  const childActivities = activities?.children || [];

  return (
    <div
      ref={ref}
      className="w-[550px] h-[550px] p-4 text-xs font-mono border-[1px] border-gray-600"
    >
      <div className="flex justify-between">
        <div>
          <p className="font-bold text-lg">{selectedPackage}</p>
          <p className="m-0 text-[10px]">Rs- {total_amount}</p>
          <p className="m-0 text-[10px]">Payment Method: Cash</p>
        </div>
        <div className="text-[10px]">
          <p>{visitingDate}</p>
          <p>{order_id}</p>
        </div>
      </div>
      <hr className="my-2" />
      <div className="text-[10px]">
        <p className="m-0">Booking Id: {booking_id}</p>
        <p className="m-0">{selectedPackage}</p>
        <p className="m-0">Nationality: {nationality}</p>
        <p className="m-0">Visiting Date: {visitingDate}</p>
        <p className="m-0">Time Slot: {selectedTimeSlot}</p>
      </div>
      <hr className="my-2" />
      <div className="flex justify-between">
        <div className="text-[10px]">
          <p className="m-0">Booking Id: {booking_id}</p>
          <div className="text-[10px]">
            <h4 className="font-bold">Activities</h4>
            {adultActivities.length > 0 && (
              <div>
                <h5 className="font-bold">Adults</h5>
                {adultActivities.map((activity, index) => (
                  <p key={index} className="m-0">
                    {activity.experience} - Rs {activity.price} x{" "}
                    {activity.count}
                  </p>
                ))}
              </div>
            )}
            {childActivities.length > 0 && (
              <div>
                <h5 className="font-bold">Children</h5>
                {childActivities.map((activity, index) => (
                  <p key={index} className="m-0">
                    {activity.experience} - Rs {activity.price} x{" "}
                    {activity.count}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>

        <img src={qr} alt="QR" className="h-[78px] w-[78px]" />
      </div>
      <hr className="my-2" />
      <div className="text-center text-[10px]">
        <p className="m-0">{message}</p>
        <p className="m-0 text-sm font-bold">{payment_status}</p>
        <p className="m-0">{utr_id}</p>
        <p className="m-0">Electronic Rates Approved #{order_id}</p>
      </div>
      <hr className="my-2" />
    </div>
  );
});

export default Ticket;
