import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Collapse,
  IconButton,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import "tailwindcss/tailwind.css";

function PreviewTicket() {
  const [ticketData, setTicketData] = useState({
    visitingDate: localStorage.getItem("visitingDate"),
    adults: localStorage.getItem("adults"),
    children: localStorage.getItem("children"),
    infants: localStorage.getItem("infants"),
    selectedPackageName: localStorage.getItem("selectedPackageName"),
    selectedTimeSlotString: localStorage.getItem("selectedTimeSlotString"),
    name: localStorage.getItem("name"),
    gender: localStorage.getItem("gender"),
    age: localStorage.getItem("age"),
    email: localStorage.getItem("email"),
    mobile: localStorage.getItem("mobile"),
    nationality: localStorage.getItem("nationality"),
    identityProofTypes: localStorage.getItem("identityProofTypes"),
    identityProofNumber: localStorage.getItem("identityProofNumber"),
    paymentMethord: localStorage.getItem("paymentMethord"),
    transactionID: localStorage.getItem("transectionID"),
  });

  const [open, setOpen] = useState(true);

  useEffect(() => {
    const handleStorageChange = () => {
      setTicketData({
        visitingDate: localStorage.getItem("visitingDate"),
        adults: localStorage.getItem("adults"),
        children: localStorage.getItem("children"),
        infants: localStorage.getItem("infants"),
        selectedPackageName: localStorage.getItem("selectedPackageName"),
        selectedTimeSlotString: localStorage.getItem("selectedTimeSlotString"),
        name: localStorage.getItem("name"),
        gender: localStorage.getItem("gender"),
        age: localStorage.getItem("age"),
        email: localStorage.getItem("email"),
        mobile: localStorage.getItem("mobile"),
        nationality: localStorage.getItem("nationality"),
        identityProofTypes: localStorage.getItem("identityProofTypes"),
        identityProofNumber: localStorage.getItem("identityProofNumber"),
        paymentMethord: localStorage.getItem("paymentMethord"),
        transactionID: localStorage.getItem("transactionID"),
      });
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen w-full mt-12 md:mt-7 ">
      <Card className="w-[80%]  p-4 shadow-lg rounded-lg">
        <Box className="flex justify-between items-center mb-4">
          <h1 className="text-blue-500 text-lg flex items-center justify-center w-full font-semibold">
            Preview
          </h1>
        </Box>

        <Grid container spacing={2}>
          {[
            { label: "Visiting Date", value: ticketData.visitingDate },
            { label: "Number of Adults", value: ticketData.adults },
            { label: "Number of Children", value: ticketData.children },
            { label: "Number of Infants", value: ticketData.infants },
            { label: "Package Name", value: ticketData.selectedPackageName },
            { label: "Time Slot", value: ticketData.selectedTimeSlotString },
            { label: "Name", value: ticketData.name },
            { label: "Gender", value: ticketData.gender },
            { label: "Age", value: ticketData.age },
            { label: "Email", value: ticketData.email },
            { label: "Mobile", value: ticketData.mobile },
            { label: "Nationality", value: ticketData.nationality },
            {
              label: "Identity Proof Type",
              value: ticketData.identityProofTypes,
            },
            {
              label: "Identity Proof Number",
              value: ticketData.identityProofNumber,
            },
            { label: "Payment Method", value: ticketData.paymentMethord },
            { label: "Transaction ID", value: ticketData.transactionID },
          ].map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box className="bg-gradient-to-r flex px-3 py-5 gap-2 from-blue-50 via-blue-100 to-blue-200 rounded-xl shadow-lg border border-blue-300">
                <Typography
                  variant="h6"
                  className="font-semibold  text-blue-900 mb-1"
                >
                  {item.label}:
                </Typography>
                <Typography variant="h6" className="text-blue-700">
                  {item.value}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Card>
    </div>
  );
}

export default PreviewTicket;
