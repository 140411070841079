import React, { useState, useEffect, useRef } from "react";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useSelector } from "react-redux";
import CounterTicketLayout from "./CounterTicketLayout";
import axios from "axios";
import { Route, Routes } from "react-router-dom";
import LoginPage from "../components/LoginPage";
import Cookies from "js-cookie";

function TicketLayout() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const bookingfor = isAuthenticated ? 2 : 1;
  // console.log(bookingfor, "bookingfor");
  const [currentPage, setCurrentPage] = useState("first");
  const [visitingDate, setVisitingDate] = useState(null);
  const reduxToken = Cookies.get("authToken");
  const userId = Cookies.get("userId");
  const [availableSeats, setAvailableSeats] = useState("");
  console.log(reduxToken, "reduxToken");

  const [persons, setPersons] = useState({
    adults: 1,
    children: 0,
    infants: 0,
  });

  const [timeLeft, setTimeLeft] = useState(540); // 9 minutes = 540 seconds
  const timerRef = useRef();

  const [configurations, setConfigrations] = useState({});
  const [holidays, setHolydays] = useState([]);
  const [holidaysByDate, setHolydaysByDate] = useState([]);
  const [timeSlot, setTimeSlot] = useState([]);
  const [packages, setPackages] = useState([]);
  // console.log(selectedPAckageName, "selectedPAckageName");

  useEffect(() => {
    axios
      .get(
        `https://zoo-api.nextindiainitiative.com/public/api/v1/common-data?api_key=${"CHANlBUoAGBjMyLch1GIh3MXW5Ga8e9pji0dPFO2dkDODAN"}`
      )
      .then((response) => {
        const data = response.data;
        if (data.status) {
          setHolydays(data?.event);
          setConfigrations(data?.configuration);
          setPackages(data?.packages);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));

    axios
      .post(
        `https://zoo-api.nextindiainitiative.com/public/api/v1/booking-calender?api_key=${"CHANlBUoAGBjMyLch1GIh3MXW5Ga8e9pji0dPFO2dkDODAN"}&booking_from=${bookingfor}`
      )
      .then((response) => {
        const data = response.data;
        if (data.status) {
          setHolydaysByDate(data?.data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));

    axios
      .get(
        `https://zoo-api.nextindiainitiative.com/public/api/v1/timeslots?api_key=${"CHANlBUoAGBjMyLch1GIh3MXW5Ga8e9pji0dPFO2dkDODAN"}&package_id=1&booking_from=${bookingfor}`
      )
      .then((response) => {
        const data = response.data;
        if (data.status) {
          setTimeSlot(data?.data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    if (currentPage !== "first") {
      startTimer();
    } else {
      clearInterval(timerRef.current);
      setTimeLeft(540);
    }

    return () => clearInterval(timerRef.current);
  }, [currentPage]);

  function startTimer() {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          setCurrentPage("first");
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  }

  return (
    <>
      {isAuthenticated ? (
        <CounterTicketLayout
          persons={persons}
          setPersons={setPersons}
          holidays={holidays}
          configurations={configurations}
          timeSlots={timeSlot}
          packages={packages}
          setVisitingDate={setVisitingDate}
          visitingDate={visitingDate}
          setTimeSlot={setTimeSlot}
          holidaysByDate={holidaysByDate}
          availableSeats={availableSeats}
        />
      ) : (
        <>
          <LoginPage />
        </>
      )}
    </>
  );
}

export default TicketLayout;
