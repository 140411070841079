import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Backdrop,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { logout } from "../redux/authSlice";
import Cookies from "js-cookie";

function Header() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const name = Cookies.get("name");
  const userId = Cookies.get("userId");
  const counter_number = Cookies.get("counter_number");
  // console.log(name, "hh");
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLinkClick = () => {
    setMobileOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const routes = [
    { name: "Home", path: "/" },

    // Add more routes as needed
  ];

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <Link>
          <img src="/logo.png" alt="Logo" width={150} className="m-3" />
        </Link>
      </Typography>
      <div className="flex flex-col items-start ml-2">
        <List>
          {routes.map((route, index) => (
            <ListItem
              button
              key={index}
              component={Link}
              to={route.path}
              onClick={handleLinkClick} // Close drawer when link is clicked
            >
              <ListItemText primary={route.name} />
            </ListItem>
          ))}
        </List>
        {/* {isAuthenticated ? (
          <Button
            sx={{ textTransform: "none" }}
            variant="outlined"
            onClick={handleLogout}
            startIcon={<Logout />}
          >
            Logout
          </Button>
        ) : (
          <Login setMobileOpen={setMobileOpen} />
        )} */}
        {/* <Button
          sx={{ textTransform: "none", mt: 2 }}
          variant="outlined"
          startIcon={<ConfirmationNumberIcon />}
          onClick={() => {
            setMobileOpen(false);
            navigate("/downloadticket");
          }}
        >
          Download Ticket
        </Button> */}
      </div>
    </Box>
  );

  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const formatTime = (date) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
    return date.toLocaleTimeString(undefined, options);
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar
          component="nav"
          position="sticky"
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[2],
          }}
        >
          <div className="md:px-[20px] xl:px-[50px]">
            <Toolbar>
              <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                <Link to={"/"}>
                  <img src="/logo.png" alt="Logo" width={150} />
                </Link>
              </Box>
              <div className="w-full pl-6">
                {isAuthenticated ? (
                  <div className="text-blue-800 border p-2 w-fit rounded-md shadow-md bg-slate-100 flex items-center justify-start gap-4 mr-4">
                    <p className="text-sm font-medium md:border-r-2 md:border-blue-300 pr-3">
                      Counter Number: {counter_number}
                    </p>
                    <p className="text-sm font-medium hidden md:block border-r-2 border-blue-300 pr-3">
                      User Name: {name}
                    </p>
                    <p className="text-sm font-medium hidden md:block border-r-2 border-blue-300 pr-3">
                      Date: {formatDate(currentDate)}
                    </p>
                    <p className="text-sm font-medium hidden md:block">
                      Time: {formatTime(currentDate)}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                sx={{
                  display: { md: "none" },
                  color: theme.palette.text.primary,
                }}
              >
                <MenuIcon />
              </IconButton>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {routes.map((route, index) => (
                  <Button
                    key={index}
                    component={Link}
                    to={route.path}
                    sx={{
                      color: theme.palette.text.primary,
                      textTransform: "none",
                    }}
                    onClick={handleLinkClick} // Close drawer when link is clicked
                  >
                    {route.name}
                  </Button>
                ))}
                {/* {isAuthenticated ? (
                  <div>
                    <Typography sx={{ mr: 2 }}>{user?.name}</Typography>
                    <IconButton
                      color="inherit"
                      onClick={handleMenuOpen}
                      sx={{ p: 0 }}
                    >
                      <Avatar alt={user?.name} src={user?.avatarUrl} />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                      sx={{ mt: "45px" }}
                    >
                      <MenuItem
                        onClick={() => {
                          navigate("/profile");
                          handleMenuClose();
                        }}
                      >
                        <ListItemIcon>
                          <AccountCircle fontSize="small" />
                        </ListItemIcon>
                        Profile
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          handleLogout();
                          handleMenuClose();
                        }}
                      >
                        <ListItemIcon>
                          <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                      </MenuItem>
                    </Menu>
                  </div>
                ) : (
                  <Login setMobileOpen={setMobileOpen} />
                )} */}
                {/* <Button
                  sx={{ textTransform: "none" }}
                  variant="outlined"
                  startIcon={<ConfirmationNumberIcon />}
                  onClick={() => navigate("/downloadticket")}
                >
                  Download Ticket
                </Button> */}
                <Box
                  sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
                >
                  <Link to={"/"}>
                    {" "}
                    <img src="/naturelogo.png" alt="Logo" width={190} />
                  </Link>{" "}
                </Box>
              </Box>
            </Toolbar>
          </div>
        </AppBar>
        <Box component="nav">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={mobileOpen}
          onClick={handleDrawerToggle}
        />
      </Box>
    </>
  );
}

export default Header;
