import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  TextField,
  IconButton,
  InputAdornment,
  CircularProgress,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { loginAsync } from "../redux/authSlice";
import { Link, useNavigate } from "react-router-dom";

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authStatus = useSelector((state) => state.auth.status);
  const authError = useSelector((state) => state.auth.error);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // const apiKey = process.env.REACT_APP_API_KEY;
  // console.log(apiKey, "apiKey");

  const handleLogin = async () => {
    if (!username || !password) {
      alert("All fields are required");
      return;
    }
    try {
      const resultAction = await dispatch(loginAsync({ username, password }));
      if (loginAsync.fulfilled.match(resultAction)) {
        navigate("/");
      } else {
        console.error("Login failed:", resultAction.payload);
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg flex flex-col md:flex-row overflow-hidden w-[90%] lg:w-[75%] md:w-[85%]">
        <div className="hidden md:flex w-1/2 items-center justify-center p-8">
          <img src="/heroimg.png" alt="Logo" className="w-3/4 " />
        </div>
        <div className="w-full md:p-10 xl:p-20 lg:p-15 md:w-1/2 p-8 flex flex-col items-center">
          <Typography component="h1" variant="h5" className="text-center mb-2">
            Counter Ticket Login :)
          </Typography>
          <form noValidate autoComplete="off" className="w-full ">
            <TextField
              autoFocus
              size="medium"
              margin="normal"
              id="username"
              label="Email Address"
              type="text"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              variant="outlined"
              className="mb-4"
            />
            <TextField
              margin="normal"
              size="medium"
              id="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              className="mb-4"
            />
            <FormControlLabel
              control={
                <Checkbox
                  // checked={showPassword}
                  // onChange={togglePasswordVisibility}
                  color="primary"
                />
              }
              label="Remember Me"
              className="mb-4"
            />
            {authError && (
              <Typography variant="body2" color="error" className="pb-2">
                {authError}
              </Typography>
            )}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleLogin}
              disabled={authStatus === "loading"}
              className="mb-4"
            >
              {authStatus === "loading" ? (
                <CircularProgress size={24} />
              ) : (
                "Login Now"
              )}
            </Button>
            <div className="flex justify-between items-center mb-4">
              <Link to="/forgot-password" className="text-blue-500">
                Forgot Password?
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
